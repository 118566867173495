import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useStyles from "./styles";
import { adminRoute } from "../../../utils";
import { logout } from "../../../store/actions/user";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { BLOG, DISCLAIMER_TERMS, LIST_USER, RPC, WHITELABEL_CONFIG } from "../../../constants";

interface NavProps {
  title: string;
  part: string;
  icon: string;
  show: boolean;
  submenu?: any;
}

const nav : NavProps[] = [
  {
    title: "List Pool",
    part: adminRoute("/campaigns"),
    icon: "icon-list-campaign",
    show: true,
  },
  // {
  //   title: "List TBA Pool",
  //   part: adminRoute("/tba-campaigns"),
  //   icon: "icon-list-campaign",
  // },
  {
    title: "Blogs",
    part: adminRoute("/blog"),
    icon: "",
    show: BLOG,
  },
  // {
  //   title: "List User",
  //   part: adminRoute("/users"),
  //   icon: "icon-users",
  //   show: true,
  // },
  
  {
    title: "List User",
    part: "",
    icon: "icon-users",
    show: true,
    submenu: LIST_USER
      ? [
          {
            title: "User",
            part: adminRoute("/users"),
            icon: "icon-users",
            show: true,
          },
          {
            title: "User Terms Logs",
            part: adminRoute("/user-terms-log"),
            icon: "icon-setting",
            show: DISCLAIMER_TERMS,
          },
        ]
      : [],
  },
  // {
  //   title: "User Referral",
  //   part: adminRoute("/referrals"),
  //   icon: "icon-history",
  // },
  {
    title: "KYC User List",
    part: adminRoute("/kyc-users"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Staking Pools",
    part: adminRoute("/staking"),
    icon: "icon-list-campaign",
    show: true,
  },
  {
    title: "List Admin",
    part: adminRoute("/admins"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Terms & Disclaimer",
    part: adminRoute("/term-and-privacy"),
    icon: "icon-setting",
    show: DISCLAIMER_TERMS,
  },
  {
    title: "Statistic",
    part: adminRoute("/statistic"),
    icon: "icon-setting",
    show: true,
  },
  {
    title: "RPC URLs",
    part: adminRoute("/rpc-url"),
    icon: "icon-rpc",
    show: RPC,
  },
  {
    title: "Config",
    part: adminRoute("/config"),
    icon: "icon-setting",
    show: true,
  }
];

if(WHITELABEL_CONFIG) {
  nav.push({
    title: "Config",
    part: adminRoute("/config"),
    icon: "icon-setting",
    show: RPC,
    submenu: 
    [
      {
        title: "Landing",
        part: adminRoute("/landing"),
        icon: "",
        show: true,
      },
      {
        title: "Faqs",
        part: adminRoute("/faqs"),
        icon: "",
        show: true,
      },
      {
        title: "Menu",
        part: adminRoute("/menu"),
        icon: "",
        show: true,
      },
      {
        title: "Tiers",
        part: adminRoute("/tiers"),
        icon: "",
        show: true,
      },
      {
        title: "Terms of Services",
        part: adminRoute("/tos"),
        icon: "",
        show: true,
      },
      {
        title: "Privacy Policy",
        part: adminRoute("/privacyPolicy"),
        icon: "",
        show: true,
      },
      {
        title: "Theme Options",
        part: adminRoute("/ThemeOptions"),
        icon: "",
        show: true,
      },
      {
        title: "Need help",
        part: adminRoute("/needHelp"),
        icon: "",
        show: true,
      },
      {
        title: "Social Media Links",
        part: adminRoute("/socialMediaLinks"),
        icon: "",
        show: true,
      },
    ]
  })
}

const NavLeft = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { smallLeft, location } = props;
  const [navLeft] = React.useState(nav);

  const logoutUser = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want logout?")) {
      return false;
    }
    dispatch(logout());
  };

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <ul className={styles.navLeft}>
      {navLeft
        .filter((item) => {
          if (item.show) return item;
        })
        .map((item, index) => {
          return (
            <>
             <li
                key={index}
                className={
                  styles.itemNavLeft +
                  " " +
                  (location?.pathname === item.part && "active")
                }
              >

                
                {item?.submenu && item?.submenu?.length > 0 ? (
                  <div onClick={() => setOpenMenu(!openMenu)} className={
                    styles.linkItemNavLeft +
                    " " +
                    (location?.pathname === item.part && "active")
                  }>
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                    {!smallLeft && (
                      <span
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        <ArrowDropDownIcon />
                      </span>
                    )}
                  </div>
                ) : (
                  <Link
                    to={item.part}
                    className={
                      styles.linkItemNavLeft +
                      " " +
                      (location?.pathname === item.part && "active")
                    }
                  >
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                  </Link>
                )}
              </li>
              {item?.submenu && item?.submenu?.length > 0 && openMenu && (
                <ul>
                  {item.submenu.map((subMenu : any, index : any) => (
                    <li
                      key={index}
                      className={
                        styles.itemNavLeft +
                        " " +
                        (location?.pathname === subMenu.part && "active")
                      }
                      style={{
                        marginLeft: "50px",
                        width: "calc(100% - 50px)",
                      }}
                    >
                      <Link
                        to={subMenu.part}
                        className={
                          styles.subItemNavLeft +
                          " " +
                          (location?.pathname === subMenu.part && "active")
                        }
                      >
                        {subMenu.icon && (
                          <i
                            className={
                              (smallLeft && " icon-small") +
                              " icon " +
                              subMenu.icon
                            }
                          ></i>
                        )}
                        {!smallLeft && subMenu.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          );
        })}

      <li className={styles.itemNavLeft}>
        <a onClick={logoutUser} className={styles.linkItemNavLeft}>
          <i
            className={(smallLeft && " icon-small") + " icon icon-setting"}
          ></i>
          {!smallLeft && "Logout"}
        </a>
      </li>
    </ul>
  );
};

export default withRouter(NavLeft);
